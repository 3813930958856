import React from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

// Bootstrap / Icons
import { Nav, Button, Form } from "react-bootstrap";
import Logo from "../assets/breed.circle.png";
import { faCabinetFiling } from "@fortawesome/pro-light-svg-icons";
import { faGrid2, faShelves } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBagShopping,
  faCartShopping,
  faBriefcase,
  faImage,
  faTimes,
  faGear,
  faSackDollar,
  faInfo,
  faTableColumns,
} from "@fortawesome/free-solid-svg-icons";

//CSS
import "../App.css";

function SideBar(props) {
  const location = useLocation();

  return (
    <div
      className={classNames("sidebar", { "is-open": props.isOpen })}
      id="sidebar-component">
      <div className="sidebar-header">
        <img src={Logo} alt="Logo" style={{ height: "30px" }} />
        {/* <Button
          variant="link"
          onClick={props.toggle}
          style={{ color: "#fff" }}
          className="mt-4">
          <FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
        </Button> */}
        <h5>Breeding Assistant</h5>
      </div>

      <Nav className="flex-column pt-2">
        {/* <Form className="ml-3">
          <Form.Group
            controlId="search "
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}>
            <Form.Control type="text" placeholder="Search..." />
          </Form.Group>
        </Form> */}

        <Nav.Item>
          <Link
            to="/orion"
            style={{
              borderBottomWidth: "2px",
              borderBottomColor: "#858796",
              borderBottomStyle: "sol",
              textDecoration: "none",
              color: "white",
              padding: "15px",
            }}
            // className="nav-link"
            // className={classNames("nav-link", {
            //   active: location.pathname === "/orion",
            // })}
          >
            <FontAwesomeIcon icon={faHome} className="mr-2" />
            <span>Home</span>
          </Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}

export default SideBar;
