import React, { useState } from "react";
import apiCalls from "../Services/AxiosCalls";
import FileTable from "./FileCrossTable";
import { Button } from "react-bootstrap";

const FileUpload = ({ fileData, traitsData }) => {
  const [crossData, setCrossData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleCrossClick = async () => {
    try {
      setLoading(true);
      const parentPromises = fileData.map(async (singleParent) => {
        console.log(singleParent);
        const parents = [singleParent["parent1"], singleParent["parent2"]];
        const traitIdsArray = traitsData.traits.map((trait) => trait.value);
        const responses = {};

        // Fetch alleles for all traits and parents
        await Promise.all(
          traitIdsArray.map(async (traitId) => {
            responses[traitId] = responses[traitId] || {};

            await Promise.all(
              parents.map(async (parent) => {
                const label = parent;
                const response = await apiCalls.getalleles(
                  traitsData.clientId.value,
                  label,
                  traitId
                );
                const responseData = response.data;
                responses[traitId][label] = responses[traitId][label] || [];
                responses[traitId][label].push(responseData);
              })
            );
          })
        );

        const simplifiedData = {};
        const prescriptionData = {};
        const graph = {};
        const allKeys = {};

        // Fetch prescriptions for all traits and parents
        await Promise.all(
          traitsData.traits.map(async (item) => {
            const value = item.value;
            const label = item.label;

            if (responses[value]) {
              const [parent1Label, parent2Label] = parents.map(
                (parent) => parent
              );

              const [prescriptionResponse, graphResponse] = await Promise.all([
                apiCalls.getprescriptions(
                  traitsData.clientId.value,
                  parent1Label,
                  parent2Label,
                  item.value
                ),
                apiCalls.getGraphs(
                  traitsData.clientId.value,
                  parent1Label,
                  parent2Label,
                  item.value
                ),
              ]);

              const graphResponseData = graphResponse.data;

              const itemsWithNone = prescriptionResponse.data
                .filter((item) => item.compare === "none")
                .map((item) => item.marker);

              const prescriptionResponseData = prescriptionResponse.data;

              simplifiedData[label] = responses[value];
              prescriptionData[label] = {
                prescription: itemsWithNone,
                parent1: parent1Label,
                parent2: parent2Label,
                cost: (
                  itemsWithNone.length *
                  0.3 *
                  graphResponseData[4].seedlings
                ).toFixed(2),
                crossName: parent1Label + "x" + parent2Label,
                seedlings: graphResponseData[4].seedlings,
                forecast: graphResponseData[3].forecast,
              };
              graph[label] = graphResponseData;
              allKeys[label] = itemsWithNone;
            }
          })
        );

        return {
          prescriptionData,
        };
      });

      const parentDataArray = await Promise.all(parentPromises);

      const combinedData = {};

      parentDataArray.forEach((dataObject) => {
        const { prescriptionData } = dataObject;

        Object.keys(prescriptionData).forEach((key) => {
          if (!combinedData[key]) {
            combinedData[key] = [];
          }

          combinedData[key].push(prescriptionData[key]);
        });
      });

      setCrossData(combinedData);
      setLoading(false);
    } catch (error) {
      console.error("Error while fetching data:", error);
      setLoading(false);
    }
  };
  const EditedData = async (data) => {
    // console.log(data);
    // setCrossData(data);
  };
  const removeData = async (data) => {
    // console.log(data);
    // setCrossData(data);
  };

  const styles = {
    colorButton: {
      backgroundColor: "#4e73df",
      border: "none",
    },
  };

  return (
    <div>
      <Button
        style={styles.colorButton}
        variant="primary"
        onClick={handleCrossClick}
        disabled={loading}>
        {loading ? "Crossing List..." : "Cross List"}
      </Button>
      {Object.keys(crossData).map((key) => {
        const data1 = crossData[key];
        const item = traitsData.traits.find((trait) => trait.label === key);

        if (data1 !== undefined && item) {
          return (
            <div
              key={item.value}
              className="table-section"
              style={{
                width: traitsData.traits.length > 1 ? "100%" : "100%",
              }}>
              <h3 className="table-title">Cross Table for {item.label}</h3>
              <FileTable
                data={data1}
                onEdit={EditedData}
                onDelete={removeData}
              />
            </div>
          );
        } else {
          return (
            <div key={key}>
              Data for {key} and the selected parents is not available.
            </div>
          );
        }
      })}
    </div>
  );
};

export default FileUpload;
