import React, { useState,useCallback } from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import apiCalls from "../Services/AxiosCalls";
import Tables from "./Table";
import CrossTable from "./CrossTable";
import Papa from "papaparse";
import FileUpload from "./FileUpload";
import CrossModal from "../components/UI/CrossModal";
import CrossHeaderIcons from "./CrossHeaderIcons";
import ToasterNotifacation from "../components/UI/Toaster";

import { CSVLink } from "react-csv";
import { useMemo } from "react";
import usersData from "../Services/template.json";

// Bootstrap
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCircleQuestion,
  faPlus,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  InputGroup,
  Modal,
} from "react-bootstrap";

// CSS
import "../Styles/Cross.css";

const Cross = () => {
  const location = useLocation();
  const data = location.state;
  const [parent1, setParent1] = useState(null);
  const [parent2, setParent2] = useState(null);
  const [childOptions, setChildOptions] = useState(false);
  const [isCrossing, setIsCrossing] = useState(false);
  const [separatedData, setSeparatedData] = useState({});
  const [prescribedData, setPrescribedData] = useState({});
  const [graphData, setGraphData] = useState({});
  const [showCrossTable, setShowCrossTable] = useState(true);
  const [crossData, setCrossData] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [fileData, setFileData] = useState([]);
  // const [modalShow, setModalShow] = useState(false);

  // console.log(data);
  const handleCrossClick = async () => {
    setIsCrossing(true);
    setChildOptions(false);

    try {
      const parents = [parent1, parent2];
      const traitIdsArray = data.traits.map((trait) => trait.value);
      const responses = {};

      // Fetch alleles for all traits and parents
      for (const traitId of traitIdsArray) {
        responses[traitId] = responses[traitId] || {};
        for (const parent of parents) {
          const label = parent.label;
          const response = await apiCalls.getalleles(
            data.clientId.value,
            label,
            traitId
          );
          const responseData = response.data;
          responses[traitId][label] = responses[traitId][label] || [];
          responses[traitId][label].push(responseData);
        }
      }

      const simplifiedData = {};
      const prescriptionData = {};
      const graph = {};

      // Fetch prescriptions for all traits and parents
      await Promise.all(
        data.traits.map(async (item) => {
          const value = item.value;
          const label = item.label;

          if (responses[value]) {
            const prescriptionResponse = await apiCalls.getprescriptions(
              data.clientId.value,
              parent1.label,
              parent2.label,
              item.value
            );

            const graphResponse = await apiCalls.getGraphs(
              data.clientId.value,
              parent1.label,
              parent2.label,
              item.value
            );

            const graphResponseData = graphResponse.data;
            const prescriptionResponseData = prescriptionResponse.data;
            // console.log(graphResponseData);

            simplifiedData[label] = responses[value];
            prescriptionData[label] = prescriptionResponseData;
            graph[label] = graphResponseData;
          }
        })
      );

      // console.log(prescriptionData);
      setSeparatedData(simplifiedData);
      setPrescribedData(prescriptionData);
      setGraphData(graph);

      // console.log("Data for all traits:", simplifiedData);
    } catch (error) {
      console.error("Error while sending parents to the endpoint:", error);
    } finally {
      setIsCrossing(false);
      setChildOptions(true);
    }
  };

  // console.log("OutAllCrossData", data);

  const addToList = useCallback(async (data) => {
    setCrossData((prevData) => [...prevData, data]);
  }, []);
  
  const EditedData = useCallback(async (data) => {
    setCrossData(data);
  }, []);
  
  const removeData = useCallback(async (data) => {
    setCrossData(data);
  }, []);
  
  const handleFileUpload = useCallback((event) => {
    setFileUpload(true);
    const file = event.target.files[0];
  
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        processCSVData(e.target.result);
      };
      reader.readAsText(file);
    }
  }, []);
  

  const parent2Options = Array.isArray(data?.data)
  ? data.data
      .filter((csi) => csi.id !== (parent1 ? parent1.value : null)) // Filter out parent1 if needed
      .map((csi) => ({ value: csi.id, label: csi.name })) // Use 'name' as the label for the select
  : [];



  // Filter options for Parent 1 based on the selected value of Parent 2
  const parent1Options = Array.isArray(data?.data)
  ? data.data
      .filter((csi) => csi.id !== (parent2 ? parent2.value : null)) // Filter out parent1 if needed
      .map((csi) => ({ value: csi.id, label: csi.name })) // Use 'name' as the label for the select
  : [];


  console.log(parent1, parent2)


  const processCSVData = (csvData) => {
    Papa.parse(csvData, {
      header: true, // Treat the first row as headers
      dynamicTyping: true, // Automatically convert numbers and booleans
      skipEmptyLines: true,
      complete: (result) => {
        // `result.data` contains an array of objects representing the CSV data
        // console.log("Parsed CSV data:", result.data);
        setFileData(result.data);
        // Now, you can handle the data as needed (e.g., store it in state or perform further processing).
      },
      error: (error) => {
        console.error("CSV parsing error:", error.message);
      },
    });
  };

  // data declared to be used in table taking data from JSON file
  const tableCsvData = useMemo(() => usersData, []);

  // Contains the column headers and table data in the required format for CSV
  const csvData = [
    ["crossId", "parent1", "parent2", "numofseed"],
    ...tableCsvData.map(({ crossId, parent1, parent2, numofseed }) => [
      crossId,
      parent1,
      parent2,
      numofseed,
    ]),
  ];

  return (
    <>
      {fileUpload && fileData ? (
        <FileUpload traitsData={data} fileData={fileData} />
      ) : (
        <div className="cross__container" style={{ zIndex: 1 }}>
          {/* <button onClick={handleShowToast}>Show Toast</button> */}
          <Card>
            <Card.Header>
              <div className="cross__header">
                <h4>Cross Parent Selection</h4>
                <CSVLink
                  className="cross__header__donwloadTeplate__btn"
                  filename="template.csv"
                  data={csvData}>
                  <FontAwesomeIcon icon={faDownload} size="sm" />
                  Download Template
                </CSVLink>
                {/* <button className="cross__header__donwloadTeplate__btn">
                  <FontAwesomeIcon icon={faDownload} size="sm" />
                  Download Template
                </button> */}
                {/* <FontAwesomeIcon icon={faUpload} size="lg" /> */}
              </div>
            </Card.Header>
            <Card.Body>
              <Row xs={1} lg={3} className="g-4">
                <Col md={3}>
                  <label className="input-label">Select Parent 1:</label>
                  <Select
                    options={parent1Options}
                    value={parent1}
                    onChange={(selectedOption) => setParent1(selectedOption)}
                    placeholder="Select Parent 1"
                  />
                </Col>
                <Col md={3}>
                  <label className="input-label">Select Parent 2:</label>
                  <Select
                    options={parent2Options}
                    value={parent2}
                    onChange={(selectedOption) => setParent2(selectedOption)}
                    placeholder="Select Parent 2"
                  />
                </Col>
                <div className="cross__header__buttons">
                  <Col className="cross__header__buttons__col" md={11}>
                    <Button
                      // variant="primary"
                      className="main-btn"
                      onClick={handleCrossClick}
                      disabled={isCrossing}>
                      {isCrossing ? "Crossing..." : "Cross"}
                    </Button>

                    <label
                      className="cross__header__input"
                      htmlFor="upload-button">
                      Upload File
                    </label>
                    <input
                      style={{ display: "none" }}
                      id="upload-button"
                      type="file"
                      onChange={handleFileUpload}
                    />
                  </Col>
                </div>

                {/* <Col md={3} className="align-self-end">
                  <Form.Group controlId="formFile" className="mb-3"> */}
                {/* <input
                    type="file"
                    onChange={handleFileUpload}
                    ref={hiddenFileInput}
                    style={{ display: "none" }} // Make the file input element invisible
                  /> */}
                {/* <label htmlFor="contained-button-file"> */}
                {/* <button className="button-upload">Upload a file</button> */}
                {/* </label> */}
                {/* <Form.Control type="file" onChange={handleFileUpload} />
                  </Form.Group>
                </Col> */}
              </Row>
            </Card.Body>
          </Card>

          {childOptions && (
            <div className="tables-container">
              {data.traits.map((item, index) => {
                const data1 = separatedData[item.label]?.[parent1.label]?.[0];
                const data2 = separatedData[item.label]?.[parent2.label]?.[0];
                const prescription = prescribedData[item.label];
                const convertPrecriptionToArray = Array(prescription);
                const graph = graphData[item.label];

                const prescriptionCount = prescription.map(
                  (data) => data.compare === "none"
                );

                // console.log("PrescriptionssCount", prescriptionCount.length);
                // console.log(
                //   "convertPrecriptionToArray",
                //   convertPrecriptionToArray
                // );

                if (data1 !== undefined && data2 !== undefined) {
                  return (
                    <div
                      key={item.value}
                      className="table-section"
                      // style={{
                      //   width: data.traits.length > 1 ? "100%" : "100%",
                      // }}
                    >
                      {/* Horizontal Header Context */}
                      <div className="table-header">
                        <h3 className="table-title">Table for {item.label}</h3>
                        {/*<div className="table_header_icons"> */}
                        <CrossHeaderIcons
                          prescription={prescription}
                          parent1={parent1.label}
                          parent2={parent2.label}
                          tableData={addToList}
                          data={graph}
                          crossData={crossData}
                          cropName={item.label}
                        />
                        {/* </div> */}
                      </div>

                      <Tables
                        data1={data1}
                        data2={data2}
                        parent1={parent1.label}
                        parent2={parent2.label}
                        markerPrescriptions={prescription}
                        graph={graph}
                        tableData={addToList}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={item.label}>
                      Data for {item.label} and the selected parents is not
                      available.
                    </div>
                  );
                }
              })}
            </div>
          )}
          {showCrossTable && crossData.length > 0 && (
            <div className="cross-table-container">
              <CrossTable
                data={crossData}
                onDelete={removeData}
                onEdit={EditedData}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Cross;
