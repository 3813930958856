import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

const ToasterNotification = ({ show, onClose, message }) => {
  return (
    <Toast
      show={show}
      onClose={onClose}
      delay={3000}
      autohide
      style={{
        position: "fixed",
        top: "10px",
        right: "10px",
        zIndex: 9999,
        // backgroundColor: "#2BD27E",
        // borderColor: "green",
        // borderWidth: "2px",
      }}>
      {/* style={{ backgroundColor: "#2BD27E" }} */}
      <Toast.Header>
        <strong className="mr-auto">Added To List</strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
};

export default ToasterNotification;
