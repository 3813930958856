import React, { createContext, useEffect, useState } from "react";
import store from "../Redux/Store";
// import * as Keychain from 'react-native-keychain';

const AuthContext = createContext(null);

const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    accessToken: null,
    refreshToken: null,
    authenticated: null,
  });

  useEffect(() => {
    // Check if a valid token exists in local storage
    const token = localStorage.getItem("token");
    // console.log("Token Available", JSON.parse(token).auth_token);
    if (token) {
      // Update authState with the token
      setAuthState({
        accessToken: JSON.parse(token).token,
        refreshToken: authState.refreshToken,
        // accessToken: JSON.parse(token).auth_token.access,
        // refreshToken: JSON.parse(token).auth_token.refresh,
        authenticated: true,
      });
    }
  }, []);

  // console.log(authState.accessToken);
  const logout = async () => {
    await localStorage.removeItem("token");
    setAuthState({
      accessToken: null,
      refreshToken: null,
      authenticated: false,
    });
  };

  const getAccessToken = () => {
    // console.log("Access_Token", authState);
    return authState.accessToken;
  };

  return (
    <Provider
      // store={store}
      value={{
        authState,
        getAccessToken,
        setAuthState,
        logout,
      }}>
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
