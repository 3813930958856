import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../Services/AuthContext";
import { logoutUser } from "../Redux/Actions/authActions";
import CartCanvas from "../components/UI/CartCanvas";

// Bootstrap / Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav, NavDropdown } from "react-bootstrap";

// CSS
import "../Styles/Navbar.css";
import logo from "../assets/BLOCK-logos_black.png";

function NavBar(props) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const authContext = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);

  // Cart Variables
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const getCartCount = () => {
    console.log("getCartCount", cartItems.length);
    return cartItems.length;
  };

  // const handleLogout = () => {
  //   // Call the logout function from AuthContext
  //   authContext.logout();
  // };
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <>
      <Navbar
        bg="light"
        className="navbar shadow-sm p-3 mb-5 bg-white rounded"
        expand>
        <Button
          style={{
            backgroundColor: "black",
            border: "0px",
            borderWidth: 0,
            color: "white",
          }}
          onClick={props.toggle}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          style={{ backgroundColor: "white" }}
          id="responsive-navbar-nav"
          className="justify-content-end">
          <Nav className="ml-auto nav-header-context" navbar>
            {/* <Nav.Link style={{ color: "black" }}>
              <CartCanvas placement="end" />
              {getCartCount() >= 1 ? (
                <span className="badge badge-warning" id="lblCartCount">
                  {getCartCount()}
                </span>
              ) : (
                <></>
              )}
            </Nav.Link> */}
            <Nav.Link style={{ color: "black" }} onClick={handleLogout}>
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default NavBar;
