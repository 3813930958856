import * as actionTypes from "../Constants/cartConstants";

const initialState = {
  cartItems: [],
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      const item = action.payload;
      console.log("cartReducerItem", item);

      // Find Item that exist in cart
      const existItem = state.cartItems.find(
        (x) => x.cropName === item.cropName && x.crossName == item.crossName
      );

      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.cropName == existItem.cropName &&
            x.crossName == existItem.crossName
              ? item
              : x
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }

    case actionTypes.EDIT_CART_ITEM:
      const { crossNameToEdit, updatedProperties } = action.payload;
      // console.log("UpdateSeed", updatedProperties.seedlings);

      // Find item in cart
      const editedCartItems = state.cartItems.map((item) =>
        item.crossName === crossNameToEdit
          ? {
              ...item,
              ...updatedProperties,
              cost: (
                item.prescription.length *
                0.3 *
                updatedProperties.seedlings
              ).toFixed(2),
            }
          : item
      );

      // Need to update seed cost
      // cost: (prescription.length * 0.3 * numberOfSeeds).toFixed(2),
      // const updateCartCost = editedCartItems.map(
      //   (item) => item.crossName === crossNameToEdit
      // );

      console.log("EditedCartItemss", editedCartItems);
      // const editedCartItems = state.cartItems.map((item) => {
      //   item.crossName === crossName ? { ...item, ...updatedProperties } : item;
      // });

      return {
        ...state,
        cartItems: editedCartItems,
      };

    case actionTypes.REMOVE_FROM_CART:
      const { cropName, crossName } = action.payload;

      const updatedCartItems = state.cartItems.filter(
        (item) => item.cropName !== cropName || item.crossName !== crossName
      );

      // const oldCode = state.cartItems.filter(
      //   (x) => x.cropName !== items.cropName && x.crossName !== items.crossName
      // );

      return {
        ...state,
        cartItems: updatedCartItems,
      };

    default:
      return state;
  }
};

const calculateCost = ({ prescription, seedlings }) => {
  return (prescription.length * 0.3 * seedlings).toFixed(2);
};
