import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ExportToCSV from "./ExportToCSV";

const FileTable = ({ data, onDelete, onEdit }) => {
  const [editingRowIndex, setEditingRowIndex] = useState(-1);
  const [editedSeedlings, setEditedSeedlings] = useState({});
  const [editedCrossName, setEditedCrossName] = useState({});
  const [mainData, setMainData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setMainData(data);
  }, [data]);

  const handleInputChange = (index, field, value) => {
    if (field === "seedlings") {
      setEditedSeedlings({ ...editedSeedlings, [index]: value });
    } else if (field === "crossName") {
      setEditedCrossName({ ...editedCrossName, [index]: value });
    }
  };

  const handleDelete = (index) => {
    // Create a new data array with the item at the specified index removed
    const newData = [...mainData];
    newData.splice(index, 1);
    // console.log(newData);
    setMainData(newData);
    // Call the `onDelete` callback with the updated data
    onDelete(newData);
  };

  const handleSave = (index) => {
    const newData = [...mainData];
    if (editedSeedlings[index] !== undefined) {
      newData[index].seedlings = editedSeedlings[index];
      newData[index].cost = (
        newData[index].prescription.length *
        0.3 *
        editedSeedlings[index]
      ).toFixed(2);
    }
    if (editedCrossName[index] !== undefined) {
      newData[index].crossName = editedCrossName[index];
    }
    // console.log(newData);
    // onEdit(newData);
    setMainData(newData);

    setEditingRowIndex(-1);
  };

  //Styles
  const styles = {
    buttonColor: {
      backgroundColor: "#4E73DF",
      border: "none",
      color: "white",
    },
    saveButtonColor: {
      backgroundColor: "#1cc88a",
      border: "none",
      color: "white",
    },
  };

  return (
    <>
      {/* <button onClick={() => navigate(-1)}> go back </button> */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Cross Name</th>
            <th>Parent 1</th>
            <th>Parent 2</th>
            <th>Prescription</th>
            <th>Forecast</th>

            <th>Seedlings</th>
            <th>Cost($)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {mainData.map((item, index) => (
            <tr key={index}>
              <td>
                {index === editingRowIndex ? (
                  <input
                    type="text"
                    value={editedCrossName[index] || item.crossName}
                    onChange={(e) =>
                      handleInputChange(index, "crossName", e.target.value)
                    }
                  />
                ) : (
                  item.crossName
                )}
              </td>

              <td>{item.parent1}</td>
              <td>{item.parent2}</td>
              <td>{item.prescription.join(", ")}</td>
              <td>{item.forecast}</td>

              <td>
                {index === editingRowIndex ? (
                  <input
                    type="number"
                    value={editedSeedlings[index] || item.seedlings}
                    onChange={(e) =>
                      handleInputChange(index, "seedlings", e.target.value)
                    }
                  />
                ) : (
                  item.seedlings
                )}
              </td>
              <td>{item.cost}</td>
              <td>
                {index === editingRowIndex ? (
                  <Button
                    variant="success"
                    style={styles.saveButtonColor}
                    onClick={() => handleSave(index)}
                    className="mx-1">
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="info"
                    style={styles.buttonColor}
                    onClick={() => setEditingRowIndex(index)}
                    className="mx-1">
                    Edit
                  </Button>
                )}
                <Button
                  variant="danger"
                  onClick={() => handleDelete(index)}
                  className="mx-1">
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ExportToCSV data={mainData} />
    </>
  );
};

export default FileTable;
