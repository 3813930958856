import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Replace the next line with your actual error logging mechanism
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={errorStyles}>
          <h2>Oops! Something went wrong.</h2>
          <p>We apologize for the inconvenience. Our team has been notified.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

const errorStyles = {
  textAlign: 'center',
  padding: '20px',
  border: '1px solid #ccc',
  borderRadius: '8px',
  backgroundColor: '#f8d7da', // Red background color for error
  color: '#721c24', // Dark red text color
};

export default ErrorBoundary;
