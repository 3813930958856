import React, { createContext, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const AxiosContext = createContext();
const { Provider } = AxiosContext;

const tokenLocal = localStorage.getItem("token");

let baseURL = "";

if (window.location.origin === "http://localhost:8080") {
  baseURL = "http://localhost:8008/api/v1/";
} else {
  baseURL = `${window.location.origin}/api/v1/`;
}

const AxiosProvider = ({ children }) => {
  const authContext = useContext(AuthContext);

  const authAxios = axios.create({
    baseURL,
  });

  const orionAuth = axios.create({
    baseURL,
  });

  const publicAxios = axios.create({
    baseURL,
  });

  // publicAxios.interceptors.request.use(
  //   (config) => {
  //     // console.log("getAccesssTTT", JSON.parse(tokenLocal).auth_token);
  //     if (!config.headers.Authorization) {
  //       config.headers[
  //         "Authorization"
  //       ] = `Bearer ${authContext.getAccessToken()}`;
  //     }
  //     console.log("Config", config);
  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );

  authAxios.interceptors.request.use(
    (config) => {
      if (!config.headers.Authorization) {
        config.headers[
          "Authorization"
        ] = `Token ${authContext.getAccessToken()}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const refreshAuthLogic = (failedRequest) => {
    const data = {
      refreshToken: authContext.authState.refreshToken,
    };

    const options = {
      method: "POST",
      data,
      url: "http://localhost:3001/api/refreshToken",
    };

    return axios(options)
      .then(async (tokenRefreshResponse) => {
        failedRequest.response.config.headers.Authorization =
          "Bearer " + tokenRefreshResponse.data.accessToken;

        authContext.setAuthState({
          ...authContext.authState,
          accessToken: tokenRefreshResponse.data.accessToken,
        });

        await localStorage.setItem(
          "token",
          JSON.stringify({
            accessToken: tokenRefreshResponse.data.accessToken,
            refreshToken: authContext.authState.refreshToken,
          })
        );

        return Promise.resolve();
      })
      .catch((e) => {
        authContext.setAuthState({
          accessToken: null,
          refreshToken: null,
        });
      });
  };

  createAuthRefreshInterceptor(authAxios, refreshAuthLogic, {});

  return (
    <Provider
      value={{
        authAxios,
        orionAuth,
        publicAxios,
      }}
    >
      {children}
    </Provider>
  );
};
export { AxiosContext, AxiosProvider };
