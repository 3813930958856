import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  REFRESH_TOKEN,
  SET_MESSAGE,
  SET_CURRENT_USER,
} from "../Constants/authConstants";

import axiosBaseURL from "../../Services/AuthUpdate/AxiosAuth";
import setAuthToken from "../../Services/AuthUpdate/setAuthToken";
import { jwtDecode } from "jwt-decode";

export const loginUser = (userData) => (dispatch) => {
  axiosBaseURL
    .post("token/login/", userData)
    .then((response) => {
      console.log("responseAction", response.data);

      // Set token to localStorage
      const token = response.data;
      // console.log(token);
      localStorage.setItem("token", token.auth_token);

      //Set Token to Auth header
      setAuthToken(token);

      // Decode token to get user data
      // const decoded = jwtDecode(token.auth_token);
      // console.log("decodedJwt", decoded);

      // Set current user
      dispatch(setCurrentUser(token));
    })
    .catch((error) => {
      console.log("Login_ERROR", error);
      dispatch({
        type: LOGIN_ERROR,
        payload: error.response.data,
      });
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("token");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const refreshToken = (accessToken) => (dispatch) => {
  dispatch({
    type: REFRESH_TOKEN,
    payload: accessToken,
  });
};
