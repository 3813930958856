import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute"; // Import your custom ProtectedRoute component
import Dashboard from "../MainPages/Dashboard";

import Cross from "../MainPages/Cross";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/orion" element={<Dashboard />} />
      <Route path="/cross" element={<Cross />} />
    </Routes>
  );
};

export default AppRoutes;
