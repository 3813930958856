import { useContext } from "react";
import { AxiosContext } from "./AxiosContext";
import axios from "axios";

let jwt;
let value;
let baseURL = "";

if (window.location.origin === "http://localhost:8080") {
  
  baseURL = "http://localhost:8008/api/v1/";
} else {
  baseURL = `${window.location.origin}/api/v1/`;
}

const authAxios = axios.create({
  baseURL
});

authAxios.interceptors.request.use(
  async (config) => {
    const value = await localStorage.getItem("token");
    if (value) {
      // jwt = JSON.parse(values);
      // console.log("JWTTT", jwt.auth_token.auth_token);
      // console.log("JWTTTDataaa", jwt);

      if (!config.headers.Authorization) {
        config.headers["Authorization"] = `Token ${value}`;
      }
    }
    console.log(config);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function httpErrorHandler(error) {
  if (error === null) throw new Error("Unrecoverable error!! Error is null!");
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = error?.response;
    const request = error?.request;
    const config = error?.config; //here we have access the config used to make the api call (we can make a retry using this conf)

    if (error.code === "ERR_NETWORK") {
      console.log(error);
    } else if (error.code === "ERR_CANCELED") {
      console.log(error);
    } else if (error.code === "ERR_BAD_REQUEST") {
      console.log(error);
    }

    if (response) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const statusCode = response?.status;
      if (statusCode === 404) {
        console.log(error);
      } else if (statusCode === 401) {
        console.log(error);
        //redirect user to login
      } else if (statusCode === 403) {
        console.log(response);
        //redirect user to login
      } else if (statusCode === 413) {
        console.log(response);
        //redirect user to login
      } else if (statusCode === 500) {
        console.log("Data Did Not Save");

        //redirect user to login
      } else if (statusCode === 504) {
        console.log(response);

        //redirect user to login
      }
    } else if (request) {
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
      console.log(request);
      //   Bugsnag.notify(response);
    }
  }
  //Something happened in setting up the request and triggered an Error
  console.log(error);
  //   Bugsnag.notify(error);
}

//GET Calls

const getClients = () => {
  return authAxios.get("administrate/");
};
const getClientCrop = (id) => {
  return authAxios.get(`crops/${id}/`);
};
const getTaits = (clientId, cropId) => {
  return authAxios.get(`prescriptions/${clientId}&${cropId}/`);
};

const getMarkers = (clientId, parent, prescription) => {
  return authAxios.get(
    `haplotypeparent_trait/${clientId}&${parent}&${[prescription]}/`
  );
};

const getParents = (clientId, prescription) => {
  return authAxios.get(`assaypositions/${clientId}&${prescription}/`);
};

const allParents = (clientId, prescription) => {
  return authAxios.get(`haplotypeparent/${clientId}&${prescription}/`);
};

const getalleles = (clientId, parentName, traitId) => {
  return authAxios.get(
    `haplotypeparent_trait/${clientId}&${parentName}&${traitId}/`
  );
};

const getprescriptions = (clientId, parent1, parent2, traitID) => {
  return authAxios.get(
    `haplotype_two_parents/${clientId}&${parent1}&${parent2}&${traitID}/`
  );
};
const getGraphs = (clientId, parent1, parent2, traitID) => {
  return authAxios.get(
    `haplotype_forecast/${clientId}&${parent1}&${parent2}&${traitID}/`
  );
};

const apiCalls = {
  getClients,
  getClientCrop,
  getTaits,
  getMarkers,
  getParents,
  allParents,
  getalleles,
  getprescriptions,
  getGraphs,
};
export default apiCalls;
