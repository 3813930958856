import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmptyCart from "../../MainPages/EmptCart";
import ExportToCSV from "../../MainPages/ExportToCSV";
import CrossTable from "../../MainPages/CrossTable";

// Cart
import { removeFromCart, editCartItem } from "../../Redux/Actions/cartActions";

// Bootstrap / Icons
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faCartShopping,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// CSS
import "../../Styles/CartCanvas.css";
// import "../../Styles/Navbar.css";

function CartCanvas({ ...props }) {
  console.log("CartCanvas", props);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const [show, setShow] = useState(false);
  const [showCrossTable, setShowCrossTable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newSeedlings, setNewSeedlings] = useState(0); // update with current seed count

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const removeFromCartHandler = (cropName, crossName) => {
    // console.log("cropNameRemove", cropName);
    // console.log("crossNameRemove", crossName);
    dispatch(removeFromCart(cropName, crossName));
  };

  function handleEditSeedlings(crossNameToEdit) {
    const updatedProperties = {
      seedlings: newSeedlings,
    };
    // Dispatch the action to edit the seedling count
    dispatch(editCartItem(crossNameToEdit, updatedProperties));
    setIsEditing(false);
  }

  // console.log("CartData", cartItems);

  // console.log("cartCountCanvas", cartItems.length);
  return (
    <>
      {/* Cart Button */}
      <FontAwesomeIcon
        // style={{ backgroundColor: "red" }}
        icon={faCartShopping}
        size="lg"
        onClick={handleShow}
      />

      {/* Canvas */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="cart-canvas-container">
        <Offcanvas.Header closeButton className="cart-canvas-header">
          <Offcanvas.Title>Cart</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body
          className={
            cartItems.length > 0 ? "cart-canvas-body" : "cart-canvas-body-empty"
          }>
          {/* Add Prescription List */}
          {cartItems.length > 0 ? (
            cartItems.map((item) => (
              <div key={item.crossName} className="cart-canvas-item-container">
                <div className="cart-canvas-item-header">
                  <ul className="cart-canvas-item-List">
                    <li style={{ fontWeight: "bold" }}>Cross Name</li>
                    <li>{item.crossName}</li>
                    <li style={{ marginTop: "10px", fontWeight: "bold" }}>
                      Parent 1
                    </li>
                    <li>{item.parent1}</li>

                    <li style={{ marginTop: "10px", fontWeight: "bold" }}>
                      Parent 2
                    </li>
                    <li>{item.parent2}</li>
                  </ul>
                </div>

                <div className="cart-canvas-item-header-context">
                  <ul className="cart-canvas-item-List">
                    <li style={{ fontWeight: "bold" }}>Forecast</li>
                    <li>{item.forecast}</li>
                    <li style={{ marginTop: "10px", fontWeight: "bold" }}>
                      Seedlings
                    </li>
                    <li>
                      {isEditing ? (
                        <input
                          className="cart-canvas-item-input"
                          type="number"
                          value={newSeedlings}
                          onChange={(e) => setNewSeedlings(e.target.value)}
                        />
                      ) : (
                        item.seedlings
                      )}
                    </li>
                  </ul>
                </div>

                <div className="cart-canvas-item-header-context">
                  <p>{item.cost}</p>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faTrash}
                    size="md"
                    onClick={() =>
                      removeFromCartHandler(item.cropName, item.crossName)
                    }
                  />
                  {isEditing ? (
                    <button
                      className="cart-canvas-item-button-save"
                      // onClick={() => setIsEditing(false)}
                      onClick={() => [
                        handleEditSeedlings(item.crossName),
                        setIsEditing(false),
                      ]}>
                      Save
                    </button>
                  ) : (
                    <FontAwesomeIcon
                      style={{ marginTop: "20px", cursor: "pointer" }}
                      icon={faPenToSquare}
                      size="md"
                      onClick={() => setIsEditing(true)}
                    />
                  )}
                </div>
              </div>
            ))
          ) : (
            <EmptyCart />
          )}
        </Offcanvas.Body>

        {/* Sticky Button as the footer */}
        {cartItems.length > 0 ? (
          <div className="cart-canvas-footer">
            <button className="cart-canvas-footer-button">Export Data</button>
          </div>
        ) : (
          <></>
        )}
      </Offcanvas>
    </>
  );
}

export default CartCanvas;
