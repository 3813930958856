import axios from "axios";

let baseURL = "";

if (window.location.origin === "http://localhost:8080") {
  baseURL = "http://localhost:8008/api/v1/";
} else {
  baseURL = `${window.location.origin}/api/v1/`;
}

const axiosBaseURL = axios.create({
  baseURL,
});

export default axiosBaseURL;
