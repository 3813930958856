import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  REFRESH_TOKEN,
  SET_CURRENT_USER,
} from "../Constants/authConstants";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  user: {},
};

export const authReducer = (state = initialState, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        // isAuthenticated: true,
        user: action.payload,
      };

    default:
      return state;
  }
};
