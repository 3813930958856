import React from "react";
import { CSVLink } from "react-csv";

const ExportToCSV = ({ data }) => {
  const headers = [
    { label: "Cross Name", key: "crossName" },
    { label: "Parent 1", key: "parent1" },
    { label: "Parent 2", key: "parent2" },
    { label: "Prescription", key: "prescription" },
    { label: "Cost", key: "cost" },
    { label: "Seedlings", key: "seedlings" },
    { label: "Forecast", key: "forecast" },
  ];

  const csvButtonStyle = {
    backgroundColor: "#4E73DF",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  };

  return (
    <div>
      <CSVLink data={data} headers={headers}>
        <button style={csvButtonStyle}>Export Data</button>
      </CSVLink>
    </div>
  );
};

export default ExportToCSV;
