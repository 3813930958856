import React, { Component } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Actions
import { loginUser } from "../Redux/Actions/authActions";

//CSS
import "./Login.css";

class TestLogin extends Component {
  constructor() {
    super();
    this.state = {
      // email: "",
      username: "",
      password: "",
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to HomeScreen
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/");
      this.props.clearCart();
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    // this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      username: this.state.username,
      password: this.state.password,
    };
    // this.props.loginUser(userData.email, userData.password)
    // this.props.loginUser(this.state.email, this.state.password);
    this.props.loginUser(userData);

    console.log("Cred", userData);
  };
  render() {
    const { errors } = this.state;
    return (
      <div className="center-container">
        <div className="login-container">
          <div className="login-card">
            {/* <img src={logo} alt="Company Logo" className="logo-img" /> */}
            <h2 className="login-heading">Sign In</h2>
            <Form onSubmit={this.onSubmit} className="login-form">
              <Form.Group controlId="username">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={this.state.username}
                  onChange={this.onChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="login-button">
                Login
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

TestLogin.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  //   errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  //   errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(TestLogin);
// export default Login;
