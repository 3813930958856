// index.js or your main entry point
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App"; // Your main application component
import { AuthProvider } from "./Services/AuthContext";
import { AxiosProvider } from "./Services/AxiosContext";
// import { FlashMessageProvider } from "./Components /FlashMessageContext";
import ErrorBoundary from "./ErrorBoundary";
import store from "./Redux/Store";
import { Provider } from "react-redux";

ReactDOM.render(
  <ErrorBoundary>
    {/* <AuthProvider>
      <AxiosProvider> */}
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
    {/* </AxiosProvider>
    </AuthProvider> */}
  </ErrorBoundary>,
  document.getElementById("root")
);
