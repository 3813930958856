import React from "react";
import { Table } from "react-bootstrap";
import ChartComponent from "./ChartComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import CrossModal from "../components/UI/CrossModal";

const Tables = ({
  data1,
  data2,
  parent1,
  parent2,
  markerPrescriptions,
  graph,
  tableData,
}) => {
  // Get the keys from the first object in the datasets
  const markers1 = [...new Set(data1.map((item) => item.marker))];
  const markers2 = [...new Set(data2.map((item) => item.marker))];
  const markers3 = [...new Set(markerPrescriptions.map((item) => item.marker))];

  console.log(data1);
  console.log(data2);

  const allMarkers = [...new Set([...markers1, ...markers2, ...markers3])];

  // Get all "ideal" values from the first dataset
  const ideals1 = data1.reduce((ideals, item) => {
    ideals[item.marker] = item.ideal;
    return ideals;
  }, {});

  // console.log(markerPrescriptions);

  // Transpose the data for markers
  const transposedData1 = {};
  data1.forEach((item) => {
    transposedData1[item.marker] = item.allele;
  });

  const transposedData2 = {};
  data2.forEach((item) => {
    transposedData2[item.marker] = item.allele;
  });

  const transposedData3 = {};
  markerPrescriptions.forEach((item) => {
    transposedData3[item.marker] = item.compare;
  });

  const keysWithNone = [];
  for (const key in transposedData3) {
    if (transposedData3[key] === "none") {
      keysWithNone.push(key);
    }
  }

  console.log("keysWithNone", keysWithNone);
  console.log("transposedData3", transposedData3);

  // console.log(transposedData3, transposedData2);

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Parent Name</th>

            {allMarkers.map((marker) => (
              <th key={marker}>{marker}</th>
            ))}
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ideal Haplotype</td>
            {allMarkers.map((marker) => (
              <td key={marker}>
                {ideals1[marker] !== undefined ? ideals1[marker] : "0"}
              </td>
            ))}
            <td></td>
          </tr>
          <tr>
            <td>{parent1}</td>

            {allMarkers.map((marker) => (
              <td key={marker}>{transposedData1[marker]}</td>
            ))}
            <td>{data1[0].category}</td>
          </tr>
          <tr>
            <td>{parent2}</td>

            {allMarkers.map((marker) => (
              <td key={marker}>{transposedData2[marker]}</td>
            ))}
            <td>{data2[0].category}</td>
          </tr>
          <tr>
            <td></td>
            {allMarkers.map((marker) => (
              <td key={marker}>
                {transposedData3[marker] === "right" && (
                  <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                )}
                {transposedData3[marker] === "wrong" && (
                  <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                )}
                {transposedData3[marker] === "none" && <span>-</span>}
              </td>
            ))}
            <td></td>
          </tr>
        </tbody>
      </Table>

      {/* Need to update size when new chart type is selected */}
      <ChartComponent
        data={graph}
        prescription={keysWithNone}
        tableData={tableData}
        parent1={parent1}
        parent2={parent2}
      />

      {/* <CrossModal prescription={keysWithNone} /> */}
    </>
  );
};

export default Tables;
