import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

// function CrossModal(props, prescription) {
const CrossModal = (props) => {
  const transposedData3 = {};
  // console.log(props);
  // console.log("MarkerP", props.markerPrescriptions);

  props.markerPrescriptions.forEach((item) => {
    transposedData3[item.marker] = item.compare;
  });

  const keysWithNone = [];
  for (const key in transposedData3) {
    if (transposedData3[key] === "none") {
      keysWithNone.push(key);
    }
  }

  const styles = {
    list: {
      listStyle: "none",
      padding: 0,
      fontSize: 14,
    },
    redIcon: {
      color: "red",
    },
    greenIcon: {
      color: "green",
    },
  };

  // console.log("DataForPres", keysWithNone);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            The prescribed markers are: {keysWithNone.join(",")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Markers Apex</h4>
          <div>
            <ul style={styles.list}>
              <li>
                <FontAwesomeIcon icon={faTimes} style={styles.redIcon} />:
                Markers fixes on the wrong direction
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} style={styles.greenIcon} />:
                Markers fixed on the right direction
              </li>
              <li>-: Markers for prescription</li>
            </ul>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default CrossModal;
