import axios from "axios";

const setAuthToken = (token) => {
  console.log(token);
  if (token) {
    // Apply Bearer token to Authorization header if token is provided
    axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${token.auth_token}`;
  } else {
    // If no token provided, remove Authorization header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
