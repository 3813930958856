// Login constants
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// Logout constanst
export const LOGOUT = "LOGOUT_SUCCESS";

// Refresh constants
export const REFRESH_TOKEN = "REFRESH_TOKEN";
