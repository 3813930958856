import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Alert, Button } from "react-bootstrap";
import Select from "react-select";
import apiCalls from "../Services/AxiosCalls";
import { useNavigate } from "react-router-dom";

// CSS
import "../App.css";

const Dashboard = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [crops, setCrops] = useState([]);
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [traits, setTraits] = useState([]);
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const styles = {
    col: {
      border: "1px solid #000",
      borderRadius: "10px",
      marginLeft: "0px",
      height: 300,
    },
  };

  const fetchData = useCallback(async (apiCall, setter, errorMsg) => {
    setError(null);
    try {
      const response = await apiCall();
      if (response.data) {
        setter(response.data);
      } else {
        setError(errorMsg);
      }
    } catch (error) {
      console.error(errorMsg, error);
      setError(errorMsg);
    }
  }, []);

  useEffect(() => {
    fetchData(apiCalls.getClients, setClients, "Error fetching clients data.");
  }, [fetchData]);

  useEffect(() => {
    if (selectedClient) {
      fetchData(
        () => apiCalls.getClientCrop(selectedClient.value),
        setCrops,
        "Error fetching crops data."
      );
    }
  }, [selectedClient, fetchData]);

  useEffect(() => {
    if (selectedCrop) {
      fetchData(
        () => apiCalls.getTaits(selectedClient.value, selectedCrop.value),
        setTraits,
        "Error fetching traits data."
      );
    }
  }, [selectedCrop, selectedClient, fetchData]);

  const handleProceed = async () => {
    if (selectedTraits.length === 0) {
      setError("Please select at least one trait to proceed.");
      return;
    }

    try {
      const selectedTraitData = await Promise.all(
        selectedTraits.map((trait) =>
          apiCalls.getParents(selectedClient.value, trait.value)
        )
      );

      if (selectedTraitData[0]?.data?.length > 0) {
        setError(null);

        const traitDataId = selectedTraitData[0].data[0].id;
        const additionalTraitData = await apiCalls.allParents(
          selectedClient.value,
          traitDataId
        );

        navigate(`/cross`, {
          state: {
            traits: selectedTraits,
            data: additionalTraitData.data,
            clientId: selectedClient,
          },
        });
      } else {
        setError("No markers found.");
      }
    } catch (error) {
      console.error("Error fetching trait data:", error);
      setError("No markers found.");
    }
  };

  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}
      <Row xs={1} lg={4} className="g-4">
        <Col>
          <Select
            options={clients.map((client) => ({
              value: client.id,
              label: client.client_name,
            }))}
            value={selectedClient}
            onChange={setSelectedClient}
            placeholder="Select a Client"
          />
        </Col>
        <Col>
          <Select
            options={crops.map((crop) => ({
              value: crop.id,
              label: crop.crop,
            }))}
            value={selectedCrop}
            onChange={setSelectedCrop}
            placeholder="Select a Crop"
          />
        </Col>
        <Col>
          <Select
            options={traits.map((trait) => ({
              value: trait.id,
              label: trait.prescription,
            }))}
            isMulti
            value={selectedTraits}
            onChange={setSelectedTraits}
            placeholder="Select Traits"
          />
        </Col>
        <Col>
          <Button className="main-btn" onClick={handleProceed} name="proceed">
            Proceed
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
