import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";

// Reducers
import { cartReducer } from "./Reducers/cartReducer";
import { authReducer } from "./Reducers/authReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  cart: cartReducer,
  auth: authReducer,
});

// Get any cart items from local storage
const cartItemsInLocalStorage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

// Checks the currrent state of the cart
const INITIAL_STATE = {
  cart: {
    cartItems: cartItemsInLocalStorage,
  },
};

const store = createStore(
  reducer,
  INITIAL_STATE,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
