import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import "../Styles/ChartComponent.css";

const options = [
  { value: "bar", label: "Bar Chart" },
  { value: "pie", label: "Pie Chart" },
  { value: "line", label: "Line Chart" },
  { value: "doughnut", label: "Doughnut Chart" },
  { value: "radar", label: "Radar Chart" },
];

const ChartComponent = ({ data }) => {
  console.log(data);
  const chartRef = useRef(null);
  const [chartType, setChartType] = useState({
    value: "bar",
    label: "Bar Chart",
  });

  useEffect(() => {
  const chartCanvas = chartRef.current;
  let categories = [];
  let values = [];
  
  // Extract the numeric data and calculate the total
  const total = data.reduce((sum, item) => {
    const key = Object.keys(item)[0];
    const value = Object.values(item)[0];
    return key !== 'seedlings' && typeof value === 'number' ? sum + value : sum;
  }, 0); // Total is 1000 (excluding seedlings)
  console.log(total);

  if (data && data.length > 0) {
    data.forEach((item) => {
      const key = Object.keys(item)[0];
      const value = Object.values(item)[0];
      if (key !== "forecast" && key !== "seedlings") {
        categories.push(key);
        values.push((value / total) * 100); // Calculate percentage
      }
    });

    const chartData = {
      labels: categories,
      datasets: [
        {
          label: "Data",
          data: values,
          backgroundColor: [
            "rgba(0, 123, 255, 0.6)",  // Bootstrap Primary Blue
            "rgba(40, 167, 69, 0.6)",   // Bootstrap Success Green
            "rgba(255, 193, 7, 0.6)",    // Bootstrap Warning Yellow
            "rgba(108, 117, 125, 0.6)",  // Bootstrap Secondary Gray
            "rgba(255, 82, 82, 0.6)"     // Bootstrap Danger Red
          ],
          borderColor: [
            "rgba(0, 123, 255, 1)",      // Bootstrap Primary Blue
            "rgba(40, 167, 69, 1)",       // Bootstrap Success Green
            "rgba(255, 193, 7, 1)",       // Bootstrap Warning Yellow
            "rgba(108, 117, 125, 1)",     // Bootstrap Secondary Gray
            "rgba(255, 82, 82, 1)"        // Bootstrap Danger Red
          ],
          
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      scales: {
        y: chartType.value === "bar" ? {
          beginAtZero: true,
          max: 100, // Cap at 100%
          ticks: {
            callback: function (value) {
              return value + "%";
            },
          },
        } : false, // Hide y-axis for non-bar charts
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
        },
        title: {
          display: false,
          text: "Chart.js Bar Chart",
        },
      },
    };

    const myChart = new Chart(chartCanvas, {
      type: chartType.value,
      data: chartData,
      options: options,
    });

    return () => {
      myChart.destroy();
    };
  }
}, [data, chartType]);


  return (
    <>
      <div className="chartComponent-container">
        <Col md={4}>
          <h3 className="input-label">Chart Type</h3>
          <Select
            options={options}
            value={chartType}
            onChange={(selectedOption) => setChartType(selectedOption)}
            placeholder="Select Chart Type"
          />
        </Col>
      </div>

      <Row className="chart-container">
        <Col md={6}>
          <div style={{ width: "120%", height: "40vh" }}>
            <canvas ref={chartRef} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ChartComponent;
