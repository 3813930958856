import React, { Component } from "react";
import SideBar from "./SideBarNavBar.js/SIdebar";
import Navbar from "./SideBarNavBar.js/Navbar";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode";
import setAuthToken from "./Services/AuthUpdate/setAuthToken";
import store from "./Redux/Store";
import { setCurrentUser, logoutUser } from "./Redux/Actions/authActions";

// Auth Login
import { AuthContext } from "./Services/AuthContext"; // Import your AuthContext
import RedirectToCASLogin from "./MainPages/NewWindow";
import Login from "./Login&Logout/Login";
import TestLogin from "./Login&Logout/TestLogin";

// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

if (localStorage.getItem("token")) {
  setAuthToken(localStorage.getItem("token"));
  // const decoded = jwtDecode(localStorage.getItem("token"));
  store.dispatch(setCurrentUser(localStorage.getItem("token")));
  // console.log("UserDecoded", decoded);
  const currentTime = Date.now() / 1000;
  // if (decoded.exp < currentTime) {
  //   console.log("Timer_Expiredd");
  // store.dispatch(logoutUser());
  // clearCart();
  // window.location.href = "/login";
  // }
}

class App extends Component {
  constructor(props) {
    super(props);

    // Mobile first
    this.state = {
      isOpen: false,
      isMobile: true,
    };

    this.previousWidth = -1;
  }

  updateWidth() {
    const width = window.innerWidth;
    const widthLimit = 576;
    const isMobile = width <= widthLimit;
    const wasMobile = this.previousWidth <= widthLimit;

    if (isMobile !== wasMobile) {
      this.setState({
        isOpen: !isMobile,
      });
    }

    this.previousWidth = width;
  }

  componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth.bind(this));
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  // Check for token to keep user logged in
  // Keeps user logged in after refresh

  render() {
    return (
      // <AuthContext.Consumer>
      <>
        {/* {(authContext) => ( */}
        {/* { this.props.auth.isAuthenticated ? ( */}
        <div className="App wrapper">
          {/* {authContext.authState.authenticated ? ( // Check if the user is authenticated */}
          {this.props.auth.isAuthenticated ? (
            <>
              <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
              <Navbar toggle={this.toggle} isOpen={this.state.isOpen} />
            </>
          ) : (
            // <RedirectToCASLogin />
            // <Link to="https://login.seednexus.com/" target="_blank" />
            <TestLogin /> // Render the Login component when not authenticated
          )}
        </div>
        {/* // )} */}
      </>
      /* </AuthContext.Consumer> */
    );
  }
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
};

// This is a function used to map the state from the Redux store to the props of the App component.
const mapStateToProps = (state) => ({
  auth: state.auth,
});

// This is exporting the App component after connecting it to the Redux store using the connect function.
export default connect(mapStateToProps, {})(App);

// export default App;
