import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CrossModal from "../components/UI/CrossModal";
import Toaster from "../components/UI/Toaster";
import ToasterNotification from "../components/UI/Toaster";

// Actions
import { addToCart } from "../Redux/Actions/cartActions";

// Bootstrap / Icons
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCircleQuestion,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
// CSS

const CrossHeaderIcons = ({
  cropName,
  prescription,
  parent1,
  parent2,
  tableData,
  data,
  crossData,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const dispatch = useDispatch();

  console.log("crossDataaaa", data);

  const transposedData3 = {};
  prescription.forEach((item) => {
    transposedData3[item.marker] = item.compare;
  });

  const keysWithNone = [];
  for (const key in transposedData3) {
    if (transposedData3[key] === "none") {
      keysWithNone.push(key);
    }
  }

  // Adding Item to cart
  function addToCartHandler() {
    const numberOfSeeds = data[4].seedlings;
    const forecast = data[3].forecast;

    dispatch(
      addToCart({
        cropName,
        crossName: parent1 + "x" + parent2,
        parent1: parent1,
        parent2: parent2,
        seedlings: numberOfSeeds,
        cost: (prescription.length * 0.3 * numberOfSeeds).toFixed(2),
        prescription: keysWithNone.join(", "),
        forecast: forecast,
      })
    );
  }

  const styles = {
    containerIcons: {
      width: "130px",
      display: "flex",
      justifyContent: "space-around",
      //backgroundColor: "red",
    },
  };
  function tableDataClick() {
    console.log("PrescriptionLength", prescription);
    const countOfPrescription = prescription.filter(
      (item) => item.compare === "none"
    );
    // console.log(countOfPrescription.length);

    tableData({
      cropName: cropName,
      crossName: parent1 + "x" + parent2,
      parent1: parent1,
      parent2: parent2,
      seedlings: data[4].seedlings,
      // cost: (prescription.length * 0.3 * data[4].seedlings).toFixed(2),
      cost: (countOfPrescription.length * 0.3 * data[4].seedlings).toFixed(2),
      prescription: keysWithNone.join(", "),
      forecast: data[3].forecast,
    });
  }

  function handleShowToast() {
    setShowToast(true);

    // Optionally, you can use setTimeout to hide the toast after a specific duration
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  }

  return (
    <div style={styles.containerIcons}>
      <ToasterNotification
        show={showToast}
        onClose={() => setShowToast(false)}
        message="Prescription has been added to list."
      />

      <CrossModal
        markerPrescriptions={prescription}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <FontAwesomeIcon
        style={{ cursor: "pointer" }}
        icon={faCircleQuestion}
        size="lg"
        onClick={() => setModalShow(true)}
      />

      <FontAwesomeIcon
        title="Add To List"
        style={{ cursor: "pointer" }}
        icon={faPlus}
        size="lg"
        onClick={() => [tableDataClick(), handleShowToast()]}
        // onClick={() => [addToCartHandler(), handleShowToast()]}
      />
    </div>
  );
};

export default CrossHeaderIcons;
